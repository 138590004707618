import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {
  ArticleForUpdateDto, BonDto, EvenementNature, FluxStatut,
  ObjectType, RechercheDxArticleDto,
  UniteDto, GetStockResult, NumeroSerieUpdateDto, BonsLexiClient, RegistresNumerosSerieLexiClient, MouvementSens,
  EcritureDto, PartenaireType, PaquetDto, StrategieComptage
} from '@lexi-clients/lexi';
import {DxDataGridComponent, DxPopupComponent, DxSelectBoxComponent, DxTabPanelComponent} from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import {NotificationType} from '../../../references/references';
import DataSource from 'devextreme/data/data_source';
import { MouvementStockDatagridComponent, ConfigFiltreMouvement } from '../../../mouvement-stock-datagrid/mouvement-stock-datagrid.component';
import { CustomStoreService } from 'lexi-angular/src/app/services/custom-store.service';
import { ArticleListService } from 'lexi-angular/src/app/services/article.service';
import { MarchandiseLigne } from '../bon-detail.component';
import { lastValueFrom } from 'rxjs';
import { ArticleListDatagridComponent } from '../../../article-list-datagrid/article-list-datagrid.component';
import { alert, confirm } from 'devextreme/ui/dialog';
import { ReferenceService } from '../../../references/references.service';
import { ConditionnementService, ConditionnementType } from 'lexi-angular/src/app/services/conditionnement.service';
import { ExportDataGridService } from 'lexi-angular/src/app/shared/services/export-data-grid.service';
import { ExportingEvent } from 'devextreme/ui/data_grid';

@Component({
  selector: 'app-bon-detail-lignes',
  templateUrl: './bon-detail-lignes.component.html',
  styleUrls: ['./bon-detail-lignes.component.scss'],
})
export class BonDetailLignesComponent implements OnInit, OnChanges {
  //#region Variables
  @Input() bon: BonDto;
  @Input() bonStrategieComptage: StrategieComptage;
  @Input() bonSens: MouvementSens;
  @Input() currentBonId: number;
  @Input() currentSiteId: number;
  @Input() currentPartenaireId: number;
  @Input() uniteDataSource: UniteDto[];
  @Input() ecritureLignes: EcritureDto[] = [];

  @Input() isCreation = false;
  @Input() isModificationEnCours = false;
  @Input() canEditQuantiteReservee = false;
  @Input() canEditQuantiteInitiale = false;
  @Input() canAddMarchandises = false;
  @Input() isCreationOuBrouillon = false;
  @Input() hasDocumentLogistique = null;
  @Input() canGererEcrituresComptables = false;
  @Input() canAfficherQuantitesTheoriquesSurUnBonInventaire = false;
  @Input() canAfficherMouvementsStock = false;
  @Input() showNoSerieInputs = false;
  @Input() isComptageEnCours: boolean = null;
  @Input() canGererParametres = false;
  @Input() indexOngletBon : number = 0;

  private _conditionnementTypeParDefaut: ConditionnementType;
  public get conditionnementTypeParDefaut(): ConditionnementType {
    return this._conditionnementTypeParDefaut;
  }

  @Input()
  public set conditionnementTypeParDefaut(value: ConditionnementType) {
    this._conditionnementTypeParDefaut = value;
    this.onConditionnementTypeChanged({value, previousValue: this.selectedConditionnementType});
  }

  private _userIsDestination = false;
  public get userIsDestination() {
    return this._userIsDestination;
  }

  @Input()
  public set userIsDestination(value) {
    this._userIsDestination = value;
    this.setNomDesQuantites();
  }

  private _userIsSource = false;
  public get userIsSource() {
    return this._userIsSource;
  }

  @Input()
  public set userIsSource(value) {
    this._userIsSource = value;
    this.setNomDesQuantites();
  }

  private _toggle: boolean;
  public get toggle(): boolean {
    return this._toggle;
  }

  @Input()
  public set toggle(value) {
    this._toggle = value;
  }

  private _marchandiseLignes: MarchandiseLigne[] = [];
  public get marchandiseLignes(): MarchandiseLigne[] {
    return this._marchandiseLignes;
  }

  @Input()
  public set marchandiseLignes(value) {
    this._marchandiseLignes = value;
    this.marchandiseLignes.forEach(ligne => {
      ligne.quantiteInitialeConvertie = this.convertirQuantite(ligne.quantiteInitiale, ligne);
      ligne.quantiteReserveeConvertie = this.convertirQuantite(ligne.quantiteReservee, ligne);
      ligne.quantiteMouvementeeConvertie = this.convertirQuantite(ligne.quantiteMouvementee, ligne);
    });
  }

  @Output() refreshStock = new EventEmitter<number[]>();
  @Output() comptageChanged = new EventEmitter<boolean>();
  @Output() marchandiseSelected = new EventEmitter<MarchandiseLigne[]>();

  @ViewChild('articleSelectBox') articleSelectBox: DxSelectBoxComponent;
  @ViewChild('dataGridMouvementMarchandise') dataGridMouvementMarchandise: MouvementStockDatagridComponent;
  @ViewChild(ArticleListDatagridComponent) articleListDatagridComponent: ArticleListDatagridComponent;
  @ViewChild('popupRechercheArticle') popupRechercheArticle: DxPopupComponent;
  @ViewChild('dataGridMarchandiseLignes') dataGridMarchandiseLignes: DxDataGridComponent;
  @ViewChild('tabPanel') tabPanel: DxTabPanelComponent;

  get exportFileName(): string {
    return this.bon == null ? 'nouveau-bon' : `bon-${this.bon.id}`;
  }

  get listeColisFileName(): string {
    return this.bon == null ? 'liste-des-colis' : `colis-du-bon-${this.bon.id}`;
  }

  get showStockSourceColumn(): boolean {
    return this.marchandiseLignes != null
      && this.marchandiseLignes.length > 0
      && this.bon?.sourceStockageId != null
      && (this.bon.partenaireSourceType == PartenaireType.interne && this.bonSens != MouvementSens.inventaire)
      ;
  }

  get showStockDestinationColumn(): boolean {
    return this.marchandiseLignes != null && this.marchandiseLignes.length > 0 && this.bon?.destinationStockageId != null && this.bonSens == MouvementSens.entree;
  }

  /** Dans le cas d'une réception uniquement, l'affichage de la quantité à recevoir est conditionné par la stratégie de comptage du bon */
  get showQuantiteInitiale(): boolean {
    return this.userIsSource || this.bon?.strategieComptage?.afficherQuantitesPrevues;
  }

  get stockSourceLegendeTexte(): string {
    const defaultText = 'Stock disponible pour la source';
    if (this.marchandiseLignes != null && this.marchandiseLignes.length > 0) {
      return this.marchandiseLignes[0].stockSource?.intitule ?? defaultText;
    }
    return defaultText;
  }

  get stockDestinationLegendeTexte(): string {
    const defaultText = 'Stock disponible pour la destination';
    if (this.marchandiseLignes != null && this.marchandiseLignes.length > 0) {
      return this.marchandiseLignes[0].stockDestination?.intitule ?? defaultText;
    }
    return defaultText;
  }

  get labelPopupNumeroSerie(): string {
    return `Renseigner ${this.marchandiseLignes?.find(x => x.bonLigneId == this.currentLigne.bonLigneId).quantiteInitiale ?? 0} numéro(s) de série`;
  }

  get boutonPositif() {
    return document.getElementById('boutton-ecart-positif');
  }

  get boutonNegatif() {
    return document.getElementById('boutton-ecart-negatif');
  }

  readonly evenementNatureDataSource = [
    {id: EvenementNature.etat, intitule: 'État'},
    {id: EvenementNature.activite, intitule: 'Activité'},
  ];

  conditionnementTypeDataSource: Array<{ id: ConditionnementType, intitule: string }> = [];
  selectedConditionnementType: ConditionnementType = ConditionnementType.base;
  uniteBaseIdForEdit: number;
  showAjoutArticlesPopup = false;
  showLegende = false;
  showStockSourceLegende = false;
  showStockDetailleLegende = false;
  showStockDestinationLegende = false;
  articleDataSource: DataSource;
  FluxStatut = FluxStatut;
  isEditingNumeroSerie: boolean = false;
  numerosSerie: string;
  currentLigne: MarchandiseLigne;
  selectedLigneNoSerieCount: number;
  typeBoutonValider: 'danger' | 'success';
  ObjectType = ObjectType;
  oldNumerosSerie: string[];
  nomQuantiteInitiale: string;
  nomQuantiteReservee: string;
  registreNoSerieSource: string[] = [];
  registreNoSerieDestination: string[] = [];
  hasFiltreEcartNegatif: boolean = false;
  hasFiltreEcartPositif: boolean = false;
  configFiltre = <ConfigFiltreMouvement> ({
    isByBon: true,
    isByPartenaire: false,
    isBySociete: false,
    isByDocumentLogistique: false,
    isByArticle: false,
  });
  PartenaireType = PartenaireType;
  MouvementSens = MouvementSens;
  showQuantiteReservee: boolean = true;
  paquets: PaquetDto[] = [];
  //#endregion

  //#region Constructeur et ngOnInit
  constructor(
    private readonly articleService: ArticleListService,
    private readonly bonsLexiClient: BonsLexiClient,
    private readonly registreNumeroSerieLexiClient: RegistresNumerosSerieLexiClient,
    private readonly referenceService: ReferenceService,
    private readonly conditionnementService: ConditionnementService,
    private readonly exportDataGridService: ExportDataGridService
  ) {
    this.conditionnementTypeDataSource = conditionnementService.getConditionnementTypes();
  }

  async ngOnChanges(): Promise<void> {
    this.showQuantiteReservee = this.bonSens == MouvementSens.inventaire || this.bon?.fluxStatut != FluxStatut.closed;
  }

  ngOnInit() {
    const additionalParams = new Map().set('onlyStockable', true);
    this.articleDataSource = new DataSource({
      key: 'id',
      paginate: true,
      pageSize: 10,
      store: new CustomStoreService(this.articleService).getSelectBoxCustomStore(additionalParams)
    });

  }

  //#endregion

  //#region Gestion des évènements
  onEditingStart(e: { key: { articleId: number, uniteId?: number, quantiteInitiale: number } }) {
    this.uniteBaseIdForEdit = e?.key?.uniteId;
  }

  async onSelectedArticlesChanged(articles: RechercheDxArticleDto[]) {
    articles = articles.filter(a => this.marchandiseLignes.findIndex(x => x.articleId == a.id) == -1);
    articles.map(async a => await this.addArticleToMarchandiseLignes(a, false));
    this.refreshStock.emit(articles.map(a => a.id));
  }

  onSelectBoxArticlePressEnter(e) {
    if (e.key === 'Enter') {
      this.onAddArticleWithSelectBox();
    }
  }

  async onAddArticleWithSelectBox() {
    if (this.articleSelectBox == null) {
      notify({closeOnClick: true, message: 'Impossible d\'ajouter l\'article. Essayez de recharger la page.'}, NotificationType.Error);
    }

    const article: RechercheDxArticleDto = this.articleSelectBox.value;
    if (!article) {
      notify({closeOnClick: true, message: 'Veuillez sélectionner un article.'}, NotificationType.Warning);
    }

    // On ajoute l'article aux lignes de marchandises uniquement si il n'est pas déjà dans le tableau
    if (this.marchandiseLignes.findIndex(m => m.articleId == article.id) == -1) {
      await this.addArticleToMarchandiseLignes(article, true);
    }
    this.articleSelectBox.value = null;
  }

  onSaving(e: { changes: Array<{ type: 'insert' | 'update' | 'delete', data: MarchandiseLigne, key: MarchandiseLigne }> }) {
    const change = e.changes[0];
    if (change && (change.type == 'insert' || change.type == 'update')) {
      if (change.data.quantiteInitialeConvertie != null) {
        change.data.quantiteInitiale = this.conditionnementService.convertirQuantiteUB(this.selectedConditionnementType, change.data.quantiteInitialeConvertie, change.key);
      }
      if (change.data.quantiteReserveeConvertie != null) {
        change.data.quantiteReservee = this.conditionnementService.convertirQuantiteUB(this.selectedConditionnementType, change.data.quantiteReserveeConvertie, change.key);
      }

      change.data.uniteId = this.uniteBaseIdForEdit;
      this.uniteBaseIdForEdit = null;
    }
  }

  onResizeEnd() {
    if (this.articleListDatagridComponent != null && this.popupRechercheArticle != null) {
      const popupHeight = this.popupRechercheArticle.instance.option('height');
      const popupTitleHeight = 43;
      const popupPadding = 16 + 16;
      this.articleListDatagridComponent.resizeDataGrid(Number(popupHeight), (popupTitleHeight + popupPadding));
    }
  }

  onComptageChanged() {
    this.comptageChanged.emit(true);
  }

  onToolbarPreparing(e) {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.unshift(
      {
        location: 'after',
        widget: 'dxSelectBox',
        options: {
          width: 200,
          dataSource: this.conditionnementTypeDataSource,
          displayExpr: 'intitule',
          valueExpr: 'id',
          placeholder: 'Sélectionnez un type de conditionnement',
          value: this.selectedConditionnementType,
          onValueChanged: this.onConditionnementTypeChanged.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Ecarts positifs',
          text: 'Ecarts +',
          onClick: this.filterByPositiveEcart.bind(this),
          elementAttr: {
            id: 'boutton-ecart-positif'
          }
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Ecarts négatifs',
          text: 'Ecarts -',
          onClick: this.filterByNegativeEcart.bind(this),
          elementAttr: {
            id: 'boutton-ecart-negatif'
          }
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          hint: 'Réinitialiser les filtres',
          icon: 'deletetable',
          onClick: this.clearDatagridFilters.bind(this),
        },
      },
    );
  }

  setQuantiteReserveeConvertieCellValue = (newData: MarchandiseLigne, value: number, currentRowData: MarchandiseLigne) => {
    newData.quantiteReserveeConvertie = value;
    const quantiteMouvementee = this.bonSens != MouvementSens.inventaire ? currentRowData.quantiteMouvementeeConvertie : 0;
    newData.ecart = newData.quantiteReserveeConvertie - currentRowData.quantiteInitialeConvertie + quantiteMouvementee;
  };

  onColisUpdated(paquets: PaquetDto[]) {
    this.paquets = paquets;
  }

  // #endregion

  // #region filtres écart
  private filterByNegativeEcart() {
    const doReturn = this.hasFiltreEcartNegatif;
    this.clearDatagridFilters();
    if (doReturn) {
      return;
    }
    this.dataGridMarchandiseLignes.instance.filter(['ecart', '<', 0]);
    this.boutonNegatif.classList.add('dx-button-default');
    this.hasFiltreEcartNegatif = true;
  }

  private filterByPositiveEcart() {
    const doReturn = this.hasFiltreEcartPositif;
    this.clearDatagridFilters();
    if (doReturn) {
      return;
    }
    this.dataGridMarchandiseLignes.instance.filter(['ecart', '>', 0]);
    this.boutonPositif.classList.add('dx-button-default');
    this.hasFiltreEcartPositif = true;
  }

  private clearDatagridFilters() {
    if (this.hasFiltreEcartPositif) {
      this.boutonPositif.classList.remove('dx-button-default');
      this.hasFiltreEcartPositif = false;
    }
    if (this.hasFiltreEcartNegatif) {
      this.boutonNegatif.classList.remove('dx-button-default');
      this.hasFiltreEcartNegatif = false;
    }
    this.dataGridMarchandiseLignes.instance.clearFilter();
  }

  //#endregion

  //#region Affichage selon le conditionnement
  private onConditionnementTypeChanged(data: { value: ConditionnementType, previousValue: ConditionnementType }) {
    this.selectedConditionnementType = data.value;

    this.marchandiseLignes?.forEach(ligne => {
      ligne.quantiteInitialeConvertie = this.convertirQuantite(ligne.quantiteInitiale, ligne);
      ligne.quantiteReserveeConvertie = this.convertirQuantite(ligne.quantiteReservee, ligne);
      ligne.quantiteMouvementeeConvertie = this.convertirQuantite(ligne.quantiteMouvementee, ligne);
    });

    this.dataGridMarchandiseLignes?.instance?.refresh();
  }

  convertirQuantite = (quantite: number, rowData: MarchandiseLigne) => {
    return this.conditionnementService.convertirQuantiteConditionnee(this.selectedConditionnementType, quantite, rowData);
  };

  detaillerEtConvertirQuantite = (rowData: MarchandiseLigne, extremite: 'source' | 'destination') => {
    const stock = extremite == 'source'
      ? rowData.stockSource
      : rowData.stockDestination;
    if (!stock.quantiteReservee) {
      return;
    }

    const stockTheoriqueConverti = this.conditionnementService.convertirQuantiteConditionnee(this.selectedConditionnementType, stock.quantiteTheorique, rowData);
    const stockReserveConverti = this.conditionnementService.convertirQuantiteConditionnee(this.selectedConditionnementType, stock.quantiteReservee, rowData);
    return `${stockTheoriqueConverti} théorique dont ${stockReserveConverti} réservé`;
  };

  calculateUniteValue = (rowData: MarchandiseLigne) => {
    return this.conditionnementService.definirUnite(this.selectedConditionnementType, rowData);
  };

  calculerEcartValue(rowData: MarchandiseLigne) {
    return this.convertirQuantite(rowData.ecart, rowData);
  }

  //#endregion

  //#endregion

  //#region Méthodes helper
  private async addArticleToMarchandiseLignes(article: RechercheDxArticleDto, requeteServeur: boolean) {
    const stockSource: GetStockResult = requeteServeur && this.bon?.sourceStockageId != null ? await lastValueFrom(this.bonsLexiClient.getStockResultByLieuStockageAndArticle(article.id, this.bon.sourceStockageId))
      : null;
    const stockDestination: GetStockResult = requeteServeur && this.bon?.destinationStockageId != null ? await lastValueFrom(this.bonsLexiClient.getStockResultByLieuStockageAndArticle(article.id, this.bon.destinationStockageId))
      : null;
    // TODO: Obtenir stockNegatifAutorise depuis l'Api. Voir si cette valeur est la même au sein du bon
    //  Changer validerUpdateQuantite en conséquence
    this.marchandiseLignes.push({
      articleId: article.id,
      articleIntitule: article.libelleLong,
      articleCodeBo: article.codeBo,
      zIndex: null,
      uniteId: article.uniteBaseId,
      quantiteInitiale: 0,
      quantiteInitialeConvertie: 0,
      quantiteReservee: 0,
      quantiteReserveeConvertie: 0,
      quantiteMouvementee: null,
      quantiteMouvementeeConvertie: null,
      ecart: 0,
      qteDisponibleResteDocument: 0,
      stockSource,
      stockDestination,
      stockNegatifAutorise: null,
    });
  }

  articleDisplayExpr(article: ArticleForUpdateDto) {
    return article && `${article.codeBo} - ${article.libelleLong}`;
  }

  private setNomDesQuantites() {
    const noms = this.referenceService.getNomsQuantites(this.bonSens);
    this.nomQuantiteInitiale = noms.quantiteInitiale;
    this.nomQuantiteReservee = noms.quantiteReservee;
  }

  quantiteComparisonTarget() {
    return 0;
  }

  //#endregion
  handleArticlePopupClose() {
    this.articleListDatagridComponent.resetFiltre();
    this.showAjoutArticlesPopup = false;
  }

  //#region Numéros de série
  onModifierNoSerie = async (val: MarchandiseLigne) => {
    this.numerosSerie = '';
    val.numerosSeries?.forEach(n => {
      if (n.trim() != '') {
        this.numerosSerie += n + '\n';
      }
    });
    this.currentLigne = val;
    const data = this.getNumerosSerieAsArray();
    this.updateQuantiteCompteeEtCouleurBoutonValider();
    this.oldNumerosSerie = data.map(x => x.numeroSerie);
    this.isEditingNumeroSerie = true;
    if (this.isModificationEnCours) {
      // On exclut l'inventaire pour ne pas récupérer les n° de série du stockage de régule
      if (this.userIsSource && this.bonSens !== MouvementSens.inventaire) {
        try {
          this.registreNoSerieSource = await lastValueFrom(this.registreNumeroSerieLexiClient.getByArticleIdEtLieuStockageId(
            this.currentLigne.articleId,
            this.bon.sourceStockageId
          ));
        } catch {
          this.registreNoSerieSource = [];
        }
      }
      if (this.userIsDestination) {
        try {
          this.registreNoSerieDestination = await lastValueFrom(this.registreNumeroSerieLexiClient.getByArticleIdEtLieuStockageId(
            this.currentLigne.articleId,
            this.bon.destinationStockageId
          ));
        } catch {
          this.registreNoSerieDestination = [];
        }
      }
    }
  };

  onCloseNumerosSerie = () => {
    this.isEditingNumeroSerie = false;
  };

  onSaveNumerosSerie = async () => {
    const numeroSerieUpdate: NumeroSerieUpdateDto[] = this.getNumerosSerieAsArray();
    const numeros = this.numerosSerie.split('\n');

    // Cas où l'utilisateur saisi un numéro de série manuellement et ne fait pas de retour à la ligne
    if (numeros[numeros.length - 1] != '') {
      this.numerosSerie += '\n';
      if (!await this.numerosSerieIsCorrect()) {
        this.updateQuantiteCompteeEtCouleurBoutonValider();
        return;
      }
    }

    this.updateQuantiteCompteeEtCouleurBoutonValider();

    // Demande confirmation si nombre n° série différent de la quantité initiale
    const currentLigne = this.marchandiseLignes.find(x => x.bonLigneId == this.currentLigne.bonLigneId);
    if (numeroSerieUpdate.length > 0 && numeroSerieUpdate.length > currentLigne.quantiteInitiale && this.bonStrategieComptage.afficherQuantitesPrevues) {
      const result = await confirm('Il y a plus de numéros de série que d\'articles prévus. Confirmez-vous les quantités saisies ?', 'Anomalie');
      if (!result) {
        return;
      }
    }

    // On modifie la quantité réservée si ce n'est pas un inventaire
    if (this.bonSens != MouvementSens.inventaire) {
      currentLigne.quantiteReservee = numeroSerieUpdate.length;
    }

    // Mise à jour en base des numéros de séries liés au bon
    await lastValueFrom(this.bonsLexiClient.updateArticleNumerosSerie(this.bon.id, numeroSerieUpdate, this.currentLigne.articleId));

    // Mise à jour graphique du textarea si on décide de saisir d'autre numéro de série
    currentLigne.numerosSeries = numeros;

    this.onCloseNumerosSerie();
  };

  hasDuplicates(array: NumeroSerieUpdateDto[]) {
    const noSerieAComparer = array.map(x => x.numeroSerie);
    return noSerieAComparer.some((element, index) => {
      return noSerieAComparer.indexOf(element) != index;
    });
  }

  removeDuplicate(array: string[]) {
    const uniqueSet: Set<string> = new Set();
    return array.filter(x => {
      if (!uniqueSet.has(x)) {
        uniqueSet.add(x);
        return true;
      }
      return false;
    });
  }

  onNumerosSerieChange() {
    const data: NumeroSerieUpdateDto[] = this.getNumerosSerieAsArray();
    this.selectedLigneNoSerieCount = data.length;
    const currentLigne = this.marchandiseLignes.find(x => x.bonLigneId == this.currentLigne.bonLigneId);
    this.typeBoutonValider = ((data.length > 0 || currentLigne.quantiteInitiale > 0)
      && data.length != currentLigne.quantiteInitiale
      && this.bonStrategieComptage.afficherQuantitesPrevues)
      ? 'danger' : 'success';
  }

  onPressEnter = async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      await this.numerosSerieIsCorrect();
    }
  };

  // On lance la vérification des numéros de série saisi dans la popup
  private async numerosSerieIsCorrect() {
    let numeroSerieValide: boolean = true;
    const data: NumeroSerieUpdateDto[] = this.getNumerosSerieAsArray();
    const dataAsStringArray: string[] = data.map(x => x.numeroSerie);
    const hasDuplicates: boolean = this.hasDuplicates(data);

    // Gestion des duplications
    if (hasDuplicates) {
      const numeroDuplique = dataAsStringArray.filter((x, y) => dataAsStringArray.indexOf(x) != y).toString();
      await alert(`Le numero de série ${numeroDuplique} déjà saisie pour l'article ${this.currentLigne.articleIntitule}.`, 'Anomalie');
      const noSerieSansDuplication = this.removeDuplicate(dataAsStringArray);
      this.numerosSerie = noSerieSansDuplication.join('\n');
      this.numerosSerie += '\n';
      this.updateQuantiteCompteeEtCouleurBoutonValider();
    }

    // Mode inventaire : Ajout d'un numéro de série sans se soucier du stockage
    else if (this.bonSens == MouvementSens.inventaire) {
      const nouveauNoSerie: string = dataAsStringArray.filter(x => !this.oldNumerosSerie.includes(x)).toString();
      dataAsStringArray.push(nouveauNoSerie);
      this.oldNumerosSerie.push(nouveauNoSerie);
    }

    // Hors inventaire : Ajout d'un nouveau numéro de série si stockage le permet
    else {
      const nouveauNoSerie: string = dataAsStringArray.filter(x => !this.oldNumerosSerie.includes(x)).toString().toUpperCase();
      // Erreur : Si on est la destination, le n° de série ne doit pas exister dans le registre de destination
      if (this.userIsDestination && this.registreNoSerieDestination.find(x => x == nouveauNoSerie) != null) {
        await this.traitementErreurInputNumeroSerie(dataAsStringArray, `Le numéro de série '"${nouveauNoSerie}"' existe déjà dans le lieu de stockage de destination`);
        numeroSerieValide = false;
      }

      // Erreur : Si on est la source, le n° de série doit exister dans le registre source
      else if (this.userIsSource && this.registreNoSerieSource.find(x => x == nouveauNoSerie) == null) {
        await this.traitementErreurInputNumeroSerie(dataAsStringArray, `Le numéro de série '"${nouveauNoSerie}"' n'existe pas dans le lieu de stockage source`);
        numeroSerieValide = false;
      }

      // On enregistre le n° de série saisi
      else {
        dataAsStringArray.push(nouveauNoSerie);
        this.oldNumerosSerie.push(nouveauNoSerie);
      }
    }

    const noSerieMisAJour = dataAsStringArray.filter(x => this.oldNumerosSerie.includes(x));
    this.selectedLigneNoSerieCount = noSerieMisAJour.length;
    // on alimente la variable qui contiendra notre futur vieille liste de numéro de série
    this.oldNumerosSerie = noSerieMisAJour;
    const textarea = document.getElementById('noSerieTextArea');
    textarea.focus();
    return numeroSerieValide;
  }

  getNombreNumerosSerie(e: string[]) {
    const nombreNumerosSerie = e?.filter(x => x != '').length;
    return nombreNumerosSerie.toString();
  }

  private getNumerosSerieAsArray(): NumeroSerieUpdateDto[] {
    const data: NumeroSerieUpdateDto[] = [];
    const numeros = this.numerosSerie.split('\n');
    numeros.forEach(x => {
      if (x.trim() != '') {
        data.push({
          articleId: this.currentLigne.articleId,
          numeroSerie: x.trim()
        });
      }
    });
    return data;
  }

  private async traitementErreurInputNumeroSerie(data: string[], message: string) {
    await alert(message, 'Anomalie');
    const noSerieMisAJour = data.filter(x => this.oldNumerosSerie.includes(x));
    this.numerosSerie = noSerieMisAJour.join('\n');
    if (noSerieMisAJour.length != 0) {
      this.numerosSerie += '\n';
    }
  }

  private updateQuantiteCompteeEtCouleurBoutonValider() {
    const data = this.getNumerosSerieAsArray();
    this.selectedLigneNoSerieCount = data.length;
    this.typeBoutonValider = ((this.currentLigne.quantiteInitiale > 0 || this.selectedLigneNoSerieCount > 0)
      && this.selectedLigneNoSerieCount != this.currentLigne.quantiteInitiale
      && this.bonStrategieComptage.afficherQuantitesPrevues)
      ? 'danger' : 'success';
  }

  //#endregion

  //#region Validation
  /** ev.value est la quantité initiale ou reservée de la ligne en cours de modification */
  validerUpdateQuantite = (ev: { value: number, data: MarchandiseLigne }): boolean => {
    if (this.isCreation || this.bonSens == MouvementSens.inventaire) {
      return true;
    }
    const valideDocument = !this.hasDocumentLogistique || (ev.value <= ev.data.qteDisponibleResteDocument);
    // TODO: quand la determination de stockNegatifAutorisé (cas ajout d'un nouvel article) est décidée, changer la ligne suivante
    const valideStock = ev.data.stockNegatifAutorise != false || (ev.value <= (ev.data.stockSource.quantiteDisponible));
    const isValide = valideDocument && valideStock && ev.value >= 0;
    return isValide;
  };

  //#endregion

    onExporting(e: ExportingEvent, filename: string) {
        this.exportDataGridService.onExporting(e, filename);
    }

  handleMarchandiseSelected(e) {
    this.marchandiseSelected.emit(e?.selectedRowsData ?? []);
  }

}
