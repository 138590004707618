import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cell-ecart',
  templateUrl: './cell-ecart.component.html',
  styleUrls: ['./cell-ecart.component.scss'],
})
export class CellEcartComponent {
  signedEcart: string;
  _ecart: number;
  @Input() set ecart(value: number) {
    this._ecart = value;
    this.signedEcart = value < 0 ? `${value}`
      : value > 0 ? `+${value}`
      : ""
    ;
  }
}
