import { Component, Input, OnInit } from '@angular/core';
import { ConnaissementLexiResultDto, ConnaissementsLexiClient, OptionTelechargementPlusieursPdfs, TypeTelechargementPdfEnum,
  ParametrageRevatuaLexiClient } from '@lexi-clients/lexi';
import { lastValueFrom } from 'rxjs';
import { ReferencesConnaissementService } from '../../modules/fret-local/connaissements/references/references-connaissement.service';
import { EtatRevatua } from '../../modules/fret-local/connaissements/references/revatua-etat';
import { DownloadService } from '../../services/download.service';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { ExportDataGridService } from '../../shared/services/export-data-grid.service';

@Component({
  selector: 'app-connaissement-list',
  templateUrl: './connaissement-list.component.html',
  styleUrls: ['./connaissement-list.component.scss'],
})
export class ConnaissementListComponent implements OnInit {
  @Input() clientId: number;

  connaissements: ConnaissementLexiResultDto[];
  selectedConnaissementDetail: ConnaissementLexiResultDto;
  popupVisible = false;
  titrePopUp: string;
  etatRevatua: EtatRevatua[];
  isParametrageFretLocalActif: boolean;

  constructor(
    private readonly connaissementsLexiClient: ConnaissementsLexiClient,
    private readonly downloadService: DownloadService,
    private readonly parametrageRevatuaLexiClient: ParametrageRevatuaLexiClient,
    private readonly referenceConnaissementService: ReferencesConnaissementService,
    private readonly exportDataGridService: ExportDataGridService
  ) { }

  async ngOnInit() {
    this.isParametrageFretLocalActif = await lastValueFrom(this.parametrageRevatuaLexiClient.isActif());
    if (this.isParametrageFretLocalActif) {
    await this.setConnaissements();
    this.etatRevatua = this.referenceConnaissementService.getEtatRevatua();
    }
  }

  async setConnaissements() {
    this.connaissements = await lastValueFrom(this.connaissementsLexiClient.getAll(null, null, null, null, this.clientId));
  }

  onConnaissementDownloadPdf = (element: any, actionFromTopBtn = false) =>  {
    const option = {id: OptionTelechargementPlusieursPdfs.pdf, libelle: "Dans un seul PDF"};
    const connaissementIds: string[] = [element.key.id];

    this.connaissementsLexiClient.getConnaissementPdfOrZipFile(
      connaissementIds,
      TypeTelechargementPdfEnum.connaissement,
      option.id,
      1,
      false,
      false,
      false,
      "response"
    ).subscribe(response => {
      this.downloadService.directDownloadFile(response, ".pdf");
    });
  }

  openDetailPopup(e: any) {
    this.selectedConnaissementDetail = e.data;
    this.popupVisible = true;
    this.titrePopUp = "N° Connaissement : " + this.selectedConnaissementDetail.codeConnaissement + ' - ' + this.selectedConnaissementDetail.destinataireIntitule;
  }

  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }
}
