<dx-tab-panel #tabPanel [deferRendering]="false" [selectedIndex]="indexOngletBon">
  <!-- Tab: Marchandises -->
  <dxi-item *ngIf="bonSens != MouvementSens.inventaire || canAfficherQuantitesTheoriquesSurUnBonInventaire" title="Marchandises" template="templateMarchandises">
    <div *dxTemplate="let item of 'templateMarchandises'">
      <dx-data-grid
        id="bonDetailLignesDataGrid"
        #dataGridMarchandiseLignes
        [dataSource]="marchandiseLignes"
        [columnAutoWidth]="true"
        [allowColumnResizing]="true"
        [showBorders]="true"
        (onSaving)="onSaving($event)"
        (onEditingStart)="onEditingStart($event)"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        (onExporting)="onExporting($event, exportFileName)"
        (onSelectionChanged)="handleMarchandiseSelected($event)"
        style="margin-top: 6px;">
        <!-- Toolbar -->
        <dxo-toolbar>
          <dxi-item location="before">
            <div *dxTemplate>
              <i id="bonDetailLignesLegendeIcon" style="font-size: 18px; cursor: pointer;" class="dx-icon-info"
                (mouseenter)="showLegende = true" (mouseleave)="showLegende = false"></i>
            </div>
          </dxi-item>
          <dxi-item location="before" *ngIf="canAddMarchandises">
            <div *dxTemplate style="margin-left: 12px;">
              <dx-button icon="add" text="Ajouter des articles" (onClick)="showAjoutArticlesPopup = true"
                [disabled]="!isModificationEnCours"></dx-button>
            </div>
          </dxi-item>
          <dxi-item location="before" *ngIf="canAddMarchandises">
            <div *dxTemplate style="display: flex; align-items: center; margin-left: 50px;">
              <dx-select-box #articleSelectBox
                [dataSource]="articleDataSource"
                [displayExpr]="articleDisplayExpr"
                searchEnabled="true"
                [searchExpr]="['libelleLong', 'codeBo']"
                [readOnly]="!isModificationEnCours"
                [width]="250" placeholder="Sélectionner un article..."
                (keydown)="onSelectBoxArticlePressEnter($event)"
              ></dx-select-box>
              <dx-button icon="add" (onClick)="onAddArticleWithSelectBox()" [disabled]="!isModificationEnCours"
                style="margin-left: 6px;"></dx-button>
            </div>
          </dxi-item>
          <dxi-item name="exportButton"></dxi-item>
          <dxi-item name="columnChooserButton"></dxi-item>
        </dxo-toolbar>

        <!-- Options -->
        <dxo-selection mode="multiple"></dxo-selection>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-header-filter [visible]="true">
          <dxo-search [enabled]="true"></dxo-search>
        </dxo-header-filter>
        <dxo-editing mode="cell"
          [allowUpdating]="isModificationEnCours"
          [allowDeleting]="isModificationEnCours && canAddMarchandises"
          [selectTextOnEditStart]="true"
        ></dxo-editing>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
        <dxo-scrolling showScrollbar="always"></dxo-scrolling>
        <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>

        <!-- Article -->
        <dxi-column dataField="articleCodeBo" caption="Code" width="120" [allowEditing]="false" cellTemplate="cellTemplate">
          <div *dxTemplate="let data of 'cellTemplate'">
            <a routerLink="/article/{{data.data.articleId}}">
              {{data.value}}
            </a>
          </div>
        </dxi-column>

        <dxi-column dataField="articleIntitule" caption="Article" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="referenceFournisseur" caption="Réf. Fournisseur" [allowEditing]="false"></dxi-column>

        <!-- Stock -->
        <dxi-column
          dataField="stockSource.quantiteDisponible"
          width="130"
          alignment="right"
          headerCellTemplate="stockSourceHeaderTemplate"
          [allowEditing]="false"
          [visible]="showStockSourceColumn"
          [showInColumnChooser]="showStockSourceColumn"
          [allowHeaderFiltering]="false"
          [allowFiltering]="false"
          cellTemplate="quantiteSourceConditionnee"
        >
          <div *dxTemplate="let data of 'stockSourceHeaderTemplate'">
            <span style="margin-right: 2px;">Stock Source</span>
            <i id="stockSourceLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
              (mouseenter)="showStockSourceLegende = true" (mouseleave)="showStockSourceLegende = false"></i>
          </div>
        </dxi-column>

        <div *dxTemplate="let cell of 'quantiteSourceConditionnee'">
          <div [ngStyle]="{'margin-right': cell.data?.stockSource?.quantiteReservee ? '0px': '18px' }">
            {{cell.value ?? 0}}
            <i *ngIf="cell.data?.stockSource?.quantiteReservee" [title]="detaillerEtConvertirQuantite(cell.data, 'source')" id="stockDetailleLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
            ></i>
          </div>
        </div>

        <div *dxTemplate="let cell of 'quantiteDestinationConditionnee'">
          <div [ngStyle]="{'margin-right': cell.data?.stockDestination?.quantiteReservee ? '0px': '18px' }">
            {{cell.value ?? 0}}
            <i *ngIf="cell.data?.stockDestination?.quantiteReservee" [title]="detaillerEtConvertirQuantite(cell.data, 'destination')" id="stockDetailleLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
            ></i>
          </div>
        </div>


        <dxi-column dataField="stockDestination.quantiteDisponible"
          width="130"
          alignment="right"
          headerCellTemplate="stockDestinationHeaderTemplate"
          [allowEditing]="false"
          [visible]="showStockDestinationColumn"
          [showInColumnChooser]="showStockDestinationColumn"
          [allowHeaderFiltering]="false"
          [allowFiltering]="false"
          cellTemplate="quantiteDestinationConditionnee">
          <div *dxTemplate="let data of 'stockDestinationHeaderTemplate'" style="display: flex; align-items: center;">
            <span style="margin-right: 2px;">Stock Dest.</span>
            <i id="stockDestinationLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
              (mouseenter)="showStockDestinationLegende = true" (mouseleave)="showStockDestinationLegende = false"></i>
          </div>
        </dxi-column>

        <!-- Quantité -->
        <dxi-column dataField="quantiteInitialeConvertie"
          [allowEditing]="canEditQuantiteInitiale"
          [caption]="nomQuantiteInitiale"
          [cssClass]="isModificationEnCours && canEditQuantiteInitiale ? 'isEditable' : ''"
          dataType="number"
          width="160"
          [allowHeaderFiltering]="false"
          [allowFiltering]="false"
          [visible]="showQuantiteInitiale"
          [showInColumnChooser]="showQuantiteInitiale"
        >
          <ng-container *ngIf="canEditQuantiteInitiale && this.bonSens != MouvementSens.inventaire">
            <dxi-validation-rule type="custom" message="Quantité dépassée" [validationCallback]="validerUpdateQuantite"></dxi-validation-rule>
            <dxi-validation-rule type="numeric" ignoreEmptyValue="false"></dxi-validation-rule>
            <dxi-validation-rule type="compare" [comparisonTarget]="quantiteComparisonTarget" comparisonType=">=" message="Une quantité ne peut être négative"></dxi-validation-rule>
          </ng-container>
        </dxi-column>

        <div *dxTemplate="let cell of 'quantiteConditionnee'">
          {{cell.value ? convertirQuantite(cell.value, cell.data) : 0}}
        </div>

        <dxi-column dataField="quantiteReserveeConvertie"
          [allowEditing]="canEditQuantiteReservee"
          [caption]="nomQuantiteReservee"
          [allowHeaderFiltering]="false"
          [allowFiltering]="false"
          [visible]="showQuantiteReservee"
          [showInColumnChooser]="showQuantiteReservee"
          [setCellValue]="setQuantiteReserveeConvertieCellValue"
          [cssClass]="isModificationEnCours && canEditQuantiteReservee ? 'isEditable' : ''"
          dataType="number" width="160"
          cellTemplate="quantiteReserveeCellTemplate"
        >
          <ng-container *ngIf="canEditQuantiteReservee">
            <dxi-validation-rule type="custom" message="Quantité dépassée" [validationCallback]="validerUpdateQuantite"></dxi-validation-rule>
            <dxi-validation-rule type="numeric" ignoreEmptyValue="false"></dxi-validation-rule>
            <dxi-validation-rule type="compare" [comparisonTarget]="quantiteComparisonTarget" comparisonType=">=" message="Une quantité ne peut être négative"></dxi-validation-rule>
          </ng-container>
          <div *dxTemplate="let cell of 'quantiteReserveeCellTemplate'">
            <span [ngClass]="{
              'text-red': calculerEcartValue(cell.data) != null && calculerEcartValue(cell.data) !== 0
            }">
              {{ cell.value }}
            </span>
          </div>
        </dxi-column>

        <dxi-column dataField="quantiteMouvementee"
          [allowEditing]="false"
          caption="Mouvementée"
          dataType="number" width="160"
          cellTemplate="quantiteConditionnee"
          [allowHeaderFiltering]="false"
          [allowFiltering]="false"
        ></dxi-column>

        <dxi-column dataField="ecart"
          caption="Écart" cellTemplate="ecartTemplate"
          dataType="number" width="75"
          [allowHeaderFiltering]="false" [allowEditing]="false"
          [allowFiltering]="false"
          [visible]="showQuantiteInitiale"
          [showInColumnChooser]="showQuantiteInitiale"
        >
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
          <div *dxTemplate="let cell of 'ecartTemplate'">
            <app-cell-ecart [ecart]="calculerEcartValue(cell.data)"></app-cell-ecart>
          </div>
        </dxi-column>

        <dxi-column dataField="qteDisponibleResteDocument"
          [allowEditing]="false"
          caption="Disp. Document"
          dataType="number"
          [visible]="false"
          [allowHeaderFiltering]="false"
          [allowFiltering]="false"
          cellTemplate="quantiteConditionnee">
        </dxi-column>

        <!-- Unité -->
        <dxi-column caption="Unité" width="130" [allowHeaderFiltering]="false" [allowFiltering]="false" [allowEditing]="false" [calculateCellValue]="calculateUniteValue">
          <dxo-lookup [dataSource]="uniteDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
        </dxi-column>

        <!-- Numéro de série -->
        <dxi-column *ngIf="showNoSerieInputs" dataField="noSerie" caption="No série" cellTemplate="numeroSerieTemplate" [allowEditing]="false" width="180px">
          <div *dxTemplate="let cellInfo of 'numeroSerieTemplate'" style="height: 14px">
            <div *ngIf="cellInfo.data.nombreNumeroSerie > 0 && cellInfo.data.articleId != null" style="margin-top: -14px;">
              <span [title]="isModificationEnCours ? '' : 'Cliquer sur modifier pour ajouter un numéro de série.'">
                <dx-button
                  icon="menu" type="normal" stylingMode="text"
                  (onClick)="onModifierNoSerie(cellInfo.data)"
                ></dx-button>
              </span>
              <i *ngIf="cellInfo.data.quantiteInitiale != (cellInfo.data.quantiteReservee + (bonSens != MouvementSens.inventaire ? cellInfo.data.quantiteMouvementee : 0))"
                class="dx-icon-warning" id="icon-warning"
                [title]="'Le nombre de numéros de série scannés ne concorde pas avec la ' + nomQuantiteInitiale"
              ></i>
              <span *ngIf="cellInfo.data.numerosSeries?.length > 0">({{ getNombreNumerosSerie(cellInfo.data.numerosSeries) }})</span>
            </div>
          </div>
        </dxi-column>
      </dx-data-grid>
    </div>
  </dxi-item>

  <!-- Tab: Comptages -->
  <dxi-item title="Comptages">
    <div *dxTemplate>
      <app-bon-detail-comptage [bon]="bon" [marchandiseLignes]="marchandiseLignes" [userIsSource]="userIsSource" [isModificationEnCours]="isModificationEnCours"
        [userIsDestination]="userIsDestination" [uniteDataSource]="uniteDataSource" [paquets]="paquets" [bonSens]="bonSens"
        [conditionnementTypeParDefaut]="conditionnementTypeParDefaut" [toggle]="toggle" [showNoSerieInputs]="showNoSerieInputs"
        [canAfficherQuantitesTheoriquesSurUnBonInventaire]="canAfficherQuantitesTheoriquesSurUnBonInventaire"
        (comptageChanged)="onComptageChanged()"
      ></app-bon-detail-comptage>
    </div>
  </dxi-item>

  <!-- Tab: Numéro de série -->
  <dxi-item *ngIf="showNoSerieInputs" title="Numéro de série" template="templateNumeroSerie">
    <div *dxTemplate="let item of 'templateNumeroSerie'" style="padding-top: 12px;">
      <app-bon-detail-numero-serie [bonId]="bon.id" [toggle]="toggle" [isComptageEnCours]="isComptageEnCours"
      ></app-bon-detail-numero-serie>
    </div>
  </dxi-item>

  <!-- Tab: Gestion des colis -->
  <dxi-item *ngIf="!isCreation && bon?.strategieComptage?.saisieNumeroColis" title="Colis" template="templateColis">
    <div *dxTemplate="let item of 'templateColis'" style="padding-top: 12px;">
      <app-bon-detail-gestion-des-colis #gestionColisRef [bonId]="currentBonId"
        [allowEditing]="bon?.fluxStatut != FluxStatut.closed" [canPointerColis]="userIsDestination"
        [exportFileName]="listeColisFileName" (colisUpdated)="onColisUpdated($event)"
      ></app-bon-detail-gestion-des-colis>
    </div>
  </dxi-item>

  <!-- Tab: Pièces-jointes -->
  <dxi-item title="Pièces-jointes">
    <div *dxTemplate>
      <app-document-list [objectId]="bon.id" [objectType]="ObjectType.bon"></app-document-list>
    </div>
  </dxi-item>

  <!-- Tab: Mouvements Marchandises -->
  <dxi-item *ngIf="!isCreation && canAfficherMouvementsStock" title="Mouvements" template="templateMouvementMarchandises">
    <div *dxTemplate="let item of 'templateMouvementMarchandises'" style="padding-top: 12px;">
      <app-mouvement-stock-datagrid
        #dataGridMouvementMarchandise
        [configFiltre]="configFiltre"
        [currentPartenaireId]="currentPartenaireId"
        [partenaireSourceId]="this.bon?.partenaireSourceId"
        [partenaireDestinationId]="this.bon?.partenaireDestinationId"
        [bonId]="currentBonId"
        [isSourceVisible]="this.bon?.partenaireSourceType != PartenaireType.externe"
        [isDestinationVisible]="this.bon?.partenaireDestinationType != PartenaireType.externe"
        [dataGridHeight]="toggle ? 'calc(100vh - 254px)' : 'calc(100vh - 361px)'"
      ></app-mouvement-stock-datagrid>
    </div>
  </dxi-item>

  <!-- Tab: Historique des flux -->
  <dxi-item *ngIf="!isCreation" title="Historique" template="templateFluxHistorique">
    <div *dxTemplate="let item of 'templateFluxHistorique'">
      <app-flux-historique-list [objectType]="ObjectType.bon" [objectId]="currentBonId" [toggle]="toggle"
      [heightDataGrid]="toggle ? 'calc(100vh - 236px)' : 'calc(100vh - 343px)'"></app-flux-historique-list>
    </div>
  </dxi-item>

  <!-- Tab: Liste des écritures -->
  <!-- TODO : A afficher ou supprimer complètement après avoir vu avec @Damien ET après avoir revérifier la récupération des écritures -->
  <!-- <dxi-item *ngIf="!isCreation && canGererEcrituresComptables" title="Ecritures" template="templateEcritures">
    <div *dxTemplate="let item of 'templateEcritures'" style="padding-top: 12px;">
      <dx-data-grid [dataSource]="ecritureLignes" [allowColumnReordering]="true" [columnAutoWidth]="true"
        [allowColumnResizing]="true" [showBorders]="true">

        <dxi-column dataField="date" caption="Date" dataType="date" format="dd/MM/yyyy" width="120"></dxi-column>
        <dxi-column dataField="codeJournal" caption="Journal" width="110"></dxi-column>
        <dxi-column dataField="numeroPiece" caption="Pièce" width="130" sortOrder="asc"></dxi-column>
        <dxi-column dataField="pieceReference" caption="Référence" width="130"></dxi-column>
        <dxi-column dataField="compteGeneral" caption="Général" width="130"></dxi-column>
        <dxi-column dataField="compteAuxiliaire" caption="Auxiliaire" width="130"></dxi-column>
        <dxi-column dataField="intitule" caption="Libellé"></dxi-column>
        <dxi-column dataField="debit" caption="Débit" width="120">
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
        </dxi-column>
        <dxi-column dataField="credit" caption="Crédit" width="120">
          <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
        </dxi-column>
        <dxo-summary>
          <dxi-total-item column="debit" summaryType="sum" displayFormat="{0} F" valueFormat="fixedPoint">
          </dxi-total-item>
          <dxi-total-item column="credit" summaryType="sum" displayFormat="{0} F" valueFormat="fixedPoint">
          </dxi-total-item>
          <dxi-group-item column="debit" summaryType="sum" displayFormat="{0} F" [alignByColumn]="true"
            valueFormat="fixedPoint">
          </dxi-group-item>
          <dxi-group-item column="credit" summaryType="sum" displayFormat="{0} F" [alignByColumn]="true"
            valueFormat="fixedPoint">
          </dxi-group-item>
        </dxo-summary>
      </dx-data-grid>
    </div>
  </dxi-item> -->

  <!-- Tab: Paramètres -->
  <dxi-item *ngIf="!isCreation && canGererParametres" title="Paramètres" template="templateParametresBon">
    <div *dxTemplate="let item of 'templateParametresBon'">
      <app-bon-detail-parametres [strategieComptage]="bonStrategieComptage" [isModificationEnCours]="isModificationEnCours"></app-bon-detail-parametres>
    </div>
  </dxi-item>
</dx-tab-panel>

<!-- Popup : Ajout de plusieurs articles -->
<dx-popup #popupRechercheArticle title="Ajouter des articles" [(visible)]="showAjoutArticlesPopup"
  [showCloseButton]="true" [resizeEnabled]="true" (onResizeEnd)="onResizeEnd()" (onHiding)="handleArticlePopupClose()">
  <dx-scroll-view>
    <app-article-list-datagrid [initComponent]="showAjoutArticlesPopup" typeSelection="multiple"
      [showStockSociete]="bon?.strategieComptage?.afficherQuantitesPrevues ?? false"
      [showStockSite]="bon?.strategieComptage?.afficherQuantitesPrevues ?? false"
      [onlyStockable]="true"
      (selectedArticlesChanged)="onSelectedArticlesChanged($event)"
      (closePopup)="showAjoutArticlesPopup = false"
      [sourceStockageId]="bon?.sourceStockageId"
      [destinationStockageId]="bon?.destinationStockageId"
      [showBoutonArticleStock] = "bonSens == MouvementSens.inventaire"
    ></app-article-list-datagrid>
  </dx-scroll-view>
</dx-popup>

<!-- Popover: Légende couleur -->
<dx-popover target="#bonDetailLignesLegendeIcon" [width]="300" [showTitle]="true" title="Légende :"
  [(visible)]="showLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    <span class="isEditable">Bleu</span> = élément modifiable<br><br>
    <span class="is-current-site">Jaune</span> = représente le site sur lequel vous vous trouvez actuellement<br><br>
  </div>
</dx-popover>

<!-- Popover: Légende Stock Source -->
<dx-popover target="#stockSourceLegendeIcon" [width]="300" [(visible)]="showStockSourceLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    {{ stockSourceLegendeTexte }}
  </div>
</dx-popover>

<!-- Popover: Légende Stock Destination -->
<dx-popover target="#stockDestinationLegendeIcon" [width]="300" [(visible)]="showStockDestinationLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    {{ stockDestinationLegendeTexte }}
  </div>
</dx-popover>

<!-- Popover: Légende Stock Detaille -->
<dx-popover target="#stockDetailleLegendeIcon" [(visible)]="showStockDetailleLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    Stock disponible (théorique - reservé)
  </div>
</dx-popover>

<!-- Popup: Edition du numéro de série-->
<dx-popup contentTemplate="editionNumeroSerie" [(visible)]="isEditingNumeroSerie" title="Édition des numéros de série"
  [showCloseButton]="true" [width]="400" [height]="530">
  <div *dxTemplate="let data of 'editionNumeroSerie'" class="popupNumeroSerie">
    <div>
      <div style="font-size: large;">{{currentLigne?.articleCodeBo}} - {{currentLigne?.articleIntitule}}</div>
      <dx-form [colCount]="1">
        <dxi-item [colSpan]="1">
          <textarea id="noSerieTextArea" cols="48" rows="21" [(ngModel)]="numerosSerie"
            (ngModelChange)="onNumerosSerieChange()" (keydown)="onPressEnter($event)"
            [disabled]="!isModificationEnCours"
          ></textarea>
        </dxi-item>
      </dx-form>
    </div>
    <div style="text-align: right; font-size: medium;">
      <div *ngIf="!bonStrategieComptage.afficherQuantitesPrevues">
        Nombre de numéros de série : {{selectedLigneNoSerieCount}}
      </div>
      <div *ngIf="bonStrategieComptage.afficherQuantitesPrevues" [ngStyle]="{'color': typeBoutonValider == 'danger' ? 'red' : '#8BC34A'}">
        Nombre de numéros de série : {{selectedLigneNoSerieCount}} / {{currentLigne?.quantiteInitiale > 0 ? currentLigne?.quantiteInitiale : 0}}
      </div>
    </div>
  </div>
  <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after"
    [options]="{text: 'Fermer', onClick: onCloseNumerosSerie}"></dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after"
    [options]="{text: 'Valider', onClick: onSaveNumerosSerie,
              disabled: !isModificationEnCours, type: bonStrategieComptage.afficherQuantitesPrevues ? typeBoutonValider : 'normal'}"></dxi-toolbar-item>
</dx-popup>
