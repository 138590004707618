<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <dx-toolbar class="toolbar-details theme-dependent">
      <dxi-item location="before">
        <dx-button icon="arrowleft" stylingMode="text" routerLink="/administration/sites"></dx-button>
      </dxi-item>
      <dxi-item location="before" text="{{ 'du_site' | internationalisation : 'Site' }} : {{ !siteReadOnly ?
          site?.intitule : '' }}">
        <!-- <span *ngIf="!isCreation">{{ 'du_site' | internationalisation: 'Site' }}: {{ !siteReadOnly ?
          site?.intitule : '' }}</span>
        <span *ngIf="isCreation">{{ 'du_site' | internationalisation: 'Site' }}:</span> -->
      </dxi-item>
      <dxi-item location="after" *ngIf="!isCreation && isEditing">
        <dx-button icon="save" type="success" stylingMode="contained" text="Enregistrer"
          (onClick)="onUpdateSite()"></dx-button>
      </dxi-item>
      <dxi-item location="after" *ngIf="!isCreation && isEditing">
        <dx-button icon="undo" type="danger" stylingMode="contained" text="Annuler"
          (onClick)="onCancelUpdate()"></dx-button>
      </dxi-item>
      <dxi-item location="after" *ngIf="isCreation">
        <dx-button icon="save" type="success" stylingMode="contained" text="Créer"
          (onClick)="onCreateNewSite()"></dx-button>
      </dxi-item>
      <dxi-item location="after">
        <dx-drop-down-button [items]="actions" sylingMode="contained" keyExpr="id" displayExpr="intitule"
          (onItemClick)="onActionClick($event)" [disabled]="isEditing" [dropDownOptions]="{ width: authService.securityUser?.isRoot ? 200 : 130 }"
          icon="smalliconslayout" text="Action"></dx-drop-down-button>
      </dxi-item>
    </dx-toolbar>

    <div class="panels theme-dependent">
      <div class="left">
        <toolbar-form [hideAllButtons]="isCreation" [isEditing]="isEditing" titleClass="dx-form-group-caption" (editingCancelled)="onCancelUpdate()"
          (editModeToggled)="handleEditClick()" (saveButtonClicked)="onUpdateSite()">
        </toolbar-form>
        <dx-form #form [formData]="site" class="form last plain-styled-form" labelMode="floating"
          [class.view-mode]="!isEditing" [readOnly]="!isEditing">
          <!-- Actif -->
          <dxi-item itemType="group" [colSpan]="5" [visible]="!site?.actif && !isCreation">
            <span class="dx-icon-warning" style="font-size: 20px; color: rgb(204, 187, 38);"></span>
            {{ 'Site' | internationalisation: 'Site' }} désactivé
          </dxi-item>

          <!-- Groupe 1 -->
          <dxi-item itemType="group" [colCount]="4">
            <dxi-item dataField="codeBo">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="intitule" [colSpan]="3" [label]="{text: 'Intitulé'}">
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [colSpan]="4" [label]="{text: 'Partenaire associé'}" dataField="partenaireId"
              editorType="dxSelectBox" [editorOptions]="{
    dataSource: partenaireDataSource,
    valueExpr: 'id',
    displayExpr: partenaireDisplayExpr,
    searchEnabled: true,
    searchExpr: ['intitule', 'codeBo']
  }">
              <dxi-validation-rule *ngIf="!isCreation" type="required"></dxi-validation-rule>
            </dxi-item>
          </dxi-item>

          <!-- Groupe 2 -->
          <dxi-item itemType="group" [colCount]="4" caption="Contact">
            <dxo-col-count-by-screen [xs]="2"></dxo-col-count-by-screen>
            <dxi-item dataField="adresse1" [colSpan]="4"></dxi-item>
            <dxi-item dataField="adresse2" [colSpan]="4"></dxi-item>
            <dxi-item dataField="telephone1" [colSpan]="2" [label]="{text: 'Téléphone 1'}"></dxi-item>
            <dxi-item dataField="telephone2" [colSpan]="2" [label]="{text: 'Téléphone 2'}"></dxi-item>
            <dxi-item dataField="email" [colSpan]="2"></dxi-item>
            <dxi-item dataField="fax" [colSpan]="2"></dxi-item>
            <dxi-item dataField="siteWeb" [colSpan]="4"></dxi-item>
          </dxi-item>

          <!-- Groupe 5 -->
          <dxi-item itemType="group">
            <dxi-item dataField="informationsGenerales" editorType="dxTextArea" [editorOptions]="{height: 173}"
              [label]="{text: 'Informations générales'}"></dxi-item>
          </dxi-item>
        </dx-form>
      </div>
      <div class="right theme-dependent">
        <div class="dx-card details-card">
          <dx-form #form [formData]="site" class="form last plain-styled-form" labelMode="floating"
            [class.view-mode]="!isEditing" [readOnly]="!isEditing">

            <dxi-item itemType="tabbed">
              <dxo-tab-panel-options [showNavButtons]="true"></dxo-tab-panel-options>
              <dxi-tab title="Général" [colCount]="4">
                <dxi-item itemType="group" caption="Administatif" [colSpan]="4" [colCount]="4">
                  <dxi-item dataField="noRidet" [label]="{text: 'Numéro Ridet'}"></dxi-item>
                  <dxi-item dataField="noRC" [label]="{text: 'Numéro RC'}"></dxi-item>
                  <dxi-item dataField="etablissement" [label]="{text: 'Établissement'}"></dxi-item>
                  <dxi-item dataField="capital"></dxi-item>
                </dxi-item>
                <!-- Groupe 4 -->
                <dxi-item itemType="group" [colSpan]="4" [colCount]="4" caption="Comptabilité">
                  <dxi-item dataField="noCompte" [label]="{text: 'N° Compte'}"
                    [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }">
                    <dxi-validation-rule message="{{ messageLongueurCompteInvalide }}" type="stringLength"
                      ignoreEmptyValue="true" [min]="longueurCompte" [max]="longueurCompte"></dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="compteAuxiliaire" [label]="{text: 'Compte Auxiliaire'}"></dxi-item>
                  <dxi-item dataField="lieuPaiementCheque" [colSpan]="2"
                    [label]="{text: 'Lieu paiement chèque'}"></dxi-item>
                  <dxi-item dataField="compteBanque" [colSpan]="4" [label]="{text: 'Compte Banque'}"
                    [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }">
                    <dxi-validation-rule type="stringLength" [min]="longueurCompte" [max]="longueurCompte"
                      ignoreEmptyValue="true" message="{{ messageLongueurCompteInvalide }}">
                    </dxi-validation-rule>
                  </dxi-item>
                </dxi-item>
                <!-- Options générales -->
                <dxi-item itemType="group" caption="Options" [colSpan]="4" [colCount]="4" labelLocation="top">
                  <!-- Groupe -->
                  <dxi-item editorType="dxCheckBox" dataField="isStock" [editorOptions]="{text: 'Est un lieu de stockage'}" [label]="{visible: false}"></dxi-item>
                  <dxi-item editorType="dxCheckBox" dataField="isPartenaire" [editorOptions]="{text: 'Est partenaire'}" [label]="{visible: false}"></dxi-item>
                  <dxi-item editorType="dxCheckBox" dataField="synchronized" [editorOptions]="{text: 'Synchronisé avec un backoffice'}" [label]="{visible: false}"></dxi-item>

                  <dxi-item dataField="entrepotTypeId" [label]="{text: 'Type de l\'entrepôt'}" editorType="dxSelectBox"
                    [editorOptions]="{
    dataSource: entrepotTypeDataSource,
    searchEnabled: true,
    displayExpr: 'libelle',
    valueExpr: 'id',
    showClearButton: true,
    searchExpr: ['libelle', 'code'],
    showDataBeforeSearch: true
  }"></dxi-item>
                </dxi-item>
                <dxi-item itemType="group" caption="Douane" [colSpan]="4" [colCount]="4">
                  <dxi-item editorType="dxCheckBox" dataField="isSousDouane" [label]="{visible: false}"
                    [editorOptions]="{text: 'Est un entrepôt sous douane'}"></dxi-item>
                  <dxi-item dataField="codeDouane"></dxi-item>
                  <dxi-item dataField="bureauxDouaneId" [label]="{text: 'Bureau de douane'}" editorType="dxSelectBox"
                    [editorOptions]="{
                dataSource: bureauxDouaneDataSource,
                searchEnabled: true,
                displayExpr: 'libelle',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['libelle', 'code'],
                showDataBeforeSearch: true
              }"></dxi-item>
                </dxi-item>
              </dxi-tab>
              <!-- TPV -->
              <dxi-tab title="Vente">
                <dxi-item itemType="group" [colCount]="4">
                  <dxi-item dataField="prixParDefautId" [label]="{text: 'Prix par défaut'}" editorType="dxSelectBox"
                    [editorOptions]="{
                dataSource: prixDataSource,
                searchEnabled: true,
                displayExpr: 'libelleLong',
                valueExpr: 'id',
                showClearButton: true,
                searchExpr: ['libelleLong', 'codeBo', 'libelleCourt'],
                showDataBeforeSearch: true
              }"></dxi-item>

                  <!-- Groupe -->
                  <dxi-item dataField="dureeValiditeBonAchat"
                    [label]="{text: 'Durée de validité d\'un bon d\'achat (en jours)'}" editorType="dxNumberBox"
                    [editorOptions]="{format: {type: 'fixedPoint', precision: 0}}">
                  </dxi-item>
                  <!-- Groupe -->

                  <dxi-item editorType="dxCheckBox" dataField="prixVenteForce" [label]="{visible: false}"
                    [editorOptions]="{text: 'Prix de vente forcé'}"></dxi-item>
                  <dxi-item editorType="dxCheckBox" dataField="isPointDeVente" [label]="{visible: false}"
                    [editorOptions]="{text: 'Est un point de vente'}"></dxi-item>
                  <dxi-item editorType="dxCheckBox" dataField="verificationStock" [label]="{visible: false}"
                    [editorOptions]="{text: 'Vérification du stock'}"></dxi-item>
                  <dxi-item editorType="dxCheckBox" dataField="avertissementStockInsuffisant" [label]="{visible: false}"
                    [editorOptions]="{text: 'Avertissement stock insuffisant'}"></dxi-item>
                  <dxi-item editorType="dxCheckBox" dataField="avertissementLotBloqueOuSansLot"
                    [label]="{visible: false}"
                    [editorOptions]="{text: 'Avertissement lot bloqué ou sans lot'}"></dxi-item>

                </dxi-item>
                <dxi-item itemType="group" caption="Point de vente">
                  <app-tpv-settings-form [colCount]="4" [tpvSettings]="site?.tpvSettings"
                    [formIsReadOnly]="!isCreation && !isEditing" [siteId]="site?.id"
                    [isLieuxStockageForSite]="true"></app-tpv-settings-form>
                </dxi-item>

                <dxi-item itemType="group" caption="Devises" [colCount]="2">
                  <!-- Groupe -->
                  <dxi-item dataField="devisePrincipaleId" [label]="{text: 'Devise principale'}"
                    editorType="dxSelectBox" [editorOptions]="{
                  dataSource: deviseDataSource,
                  searchEnabled: true,
                  displayExpr: 'intitule',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule', 'codeISO'],
                    showDataBeforeSearch: true
                }"></dxi-item>
                  <dxi-item dataField="deviseSecondaireId" [label]="{text: 'Devise secondaire'}"
                    editorType="dxSelectBox" [editorOptions]="{
                  dataSource: deviseDataSource,
                  searchEnabled: true,
                  displayExpr: 'intitule',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule', 'codeISO'],
                  showDataBeforeSearch: true
                }"></dxi-item>
                  <dxi-item [colSpan]="2">
                    <app-gestion-devises *ngIf="siteId" [siteId]="siteId"
                      [siteReadOnly]="!isEditing"></app-gestion-devises>
                  </dxi-item>
                </dxi-item>
                <!-- Groupe -->
              </dxi-tab>

              <dxi-tab title="Nature des pièces" *ngIf="siteId" colCount="4">
                <app-magasin-piece-nature-datagrid [siteId]="siteId"
                  [siteReadOnly]="!isEditing"></app-magasin-piece-nature-datagrid>
              </dxi-tab>

              <dxi-tab title="Modes de règlement" colCount="4">
                <app-gestion-mode-reglement-magasin *ngIf="siteId" [siteId]="siteId"
                  [siteReadOnly]="!isEditing"></app-gestion-mode-reglement-magasin>
              </dxi-tab>

              <dxi-tab title="Fret Local" colCount="1"
                *ngIf="currentTypeUtilisateurRuunui == TypeUtilisateurRuunui.armateur">
                <app-voyage-fret-local-settings-form [colCount]="3" [colSpan]="1"
                  [voyageFretLocalSettings]="site?.fretLocalSettings" [formIsReadOnly]="!isEditing"
                  [motifRefusDemande]="motifRefusDemande"></app-voyage-fret-local-settings-form>
              </dxi-tab>

              <!-- Tab Logistique -->
              <dxi-tab title="Logistique">
                <dxi-item [colSpan]="4" dataField="lieuStockageDefautId" [label]="{text: 'Lieu de stockage par défaut'}"
                  editorType="dxSelectBox" [editorOptions]="{
      dataSource: lieuStockageDataSource,
      searchEnabled: true,
      displayExpr: lieuStockageDisplayExpr,
      valueExpr: 'id',
      showClearButton: true,
      searchExpr: ['intitule', 'codeBo'],
      showDataBeforeSearch: true
    }"></dxi-item>
                <dxi-item itemType="group">
                  <div *dxTemplate>
                    <app-gestion-logistique [colCount]="4" [colSpan]="1" [logistiqueSettings]="site?.logistiqueSettings"
                      [formIsReadOnly]="!isEditing" [isFromSociete]="isFromSociete"></app-gestion-logistique>
                  </div>
                </dxi-item>
              </dxi-tab>

              <!-- Lieux de stockage -->
              <dxi-tab title="Lieux de stockage">
                <app-lieu-stockage-tree-list [partenaireId]="site?.partenaireId"
                  [enableActions]="isEditing && canGererLieuxStockages"
                  [currentSiteId]="siteId"></app-lieu-stockage-tree-list>
              </dxi-tab>
            </dxi-item>

          </dx-form>
        </div>
      </div>
    </div>
  </div>
</dx-scroll-view>

<dx-load-panel [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true" [shading]="true"
  [hideOnOutsideClick]="false"></dx-load-panel>